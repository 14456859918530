import React from "react";
import "./Navbar.scss";
import AnchorLink from 'react-anchor-link-smooth-scroll'
export default class Navbar extends React.Component {
    render() {
        return (
            <nav className="navbar">
                <ul className="navbar__list">
                    <AnchorLink href="#about" className="navbar__item-link">
                        <li className="navbar__item">ABOUT</li>
                    </AnchorLink>
                    <AnchorLink href="#work" className="navbar__item-link">
                        <li className="navbar__item">WORK</li>
                    </AnchorLink>
                    <AnchorLink href="#contact" className="navbar__item-link">
                        <li className="navbar__item">CONTACT</li>
                    </AnchorLink>
                </ul>
            </nav>
        )
    }
}