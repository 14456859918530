import React from "react"
import "../about/About.scss"
import Header from "../header/Header";

export default class About extends React.Component {
    render() {
        return (
            <section className="about" id="about">
                <Header header={this.props.header} />
                <div className="about__content">Hi! I'm Peter.<br></br> I began coding about two years ago while studying philosophy in university.
                Since then, I have made coding a full time learning journey and am greatful to be part of a community that is willing to share vast  amounts of knowledge, tools, and support.<br /> When I'm not wondering about philosophical concepts, social affairs, or screaming at a television over a football match, you'll likely find me thinking about how web applications and software is built. Below is a glimpse of some past and current projects. Have a look!
                </div>
            </section>
        )
    }
}
