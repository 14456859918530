import React from "react";
import "../contact/Contact.scss";
import Header from "../header/Header";
export default class Contact extends React.Component {
    render() {
        return (
            <section className="contact" id="contact">
                <Header header={this.props.header} />
                <div className="contact__info-wrapper">
                    <h3 className="contact__name">PETER XRISTIDIS (CHRIS-TEE-DEES)</h3>
                    <span className="contact__email">hello@petecodes.com</span>
                </div>
                <div className="contact__social-wrapper">
                    <a className="contact__social-link" href="https://www.linkedin.com/in/peterchristidis/" target="_blank">
                        <span className="contact__social-link">LINKEDIN</span>
                    </a>
                    <a className="contact__social-link" href="https://github.com/xristidis" target="_blank">
                        <span className="contact__social-link">GITHUB</span>
                    </a>
                    <a className="contact__social-link" href="https://twitter.com/petePrograms" target="_blank">
                        <span className="contact__social-link">TWITTER</span>
                    </a>
                </div>
                <div className="contact__bottom">
                    <div className="contact__overflow"></div>
                </div>
            </section>
        )
    }
}