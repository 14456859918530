import React from "react";
import "../hero/Hero.scss"
export default class Header extends React.Component {
    render() {
        return (
            <section className="hero">
                <div className="hero__design-wrapper">
                    <div className="hero__line"></div>
                    <div className="hero__header-wrapper">
                        <h1 className="hero__header">PETECODES</h1>
                        <h1 className="hero__header hero__sub-header">BY PETER XRISTIDIS</h1>
                    </div>
                    <div className="hero__line"></div>
                </div>
            </section>
        )
    }
}