import React from "react";
import "../header/Header.scss"

export default class Header extends React.Component {
    render() {
        return (
            <div className="header__wrapper">
                <h1 className="header">{this.props.header}</h1>
            </div>
        )
    }
}