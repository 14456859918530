import React from "react";
import "../footer/Footer.scss";
import ReactIcon from "../../assets/images/react.ico"
export default class Footer extends React.Component {
    render() {
        return (
            <section className="footer">
                <div className="footer__child-overlay"></div>
                <span className="footer__text">Built with React ❤️<img className="footer__react-icon" src={ReactIcon} alt="" /></span>
            </section>
        )
    }
}