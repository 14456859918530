import React from "react";
import "./Homepage.scss";
import NavBar from "../components/nav-bar/Navbar";
import Hero from "../components/hero/Hero";
import About from "../components/about/About";
import Work from "../components/work/Work";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
class Homepage extends React.Component {
    state = {
        headers: {
            about: "ABOUT",
            work: "PROJECTS",
            contact: "CONTACT"
        },
        work: [
            {
                image: require('../assets/images/bandsiteSS.png'),
                title: "Bandsite",
                github: "https://github.com/Xristidis/peter-christidis-bandsite",
                githubButton: "work__item-github-link",
                animate: "work__item-img-wrapper",
                link: "https://bandsite-production.netlify.app",
                description: "Bandsite was a project mainly focused around implementing clean and responsive design, along with the implementation of the BEM methodology. It uses mock data from an API to recieve data on tour information from a band. ",
                tools: ["Javascript", "Axios", "SCSS", "Flexbox", "Netlify"]
            },
            {
                image: require('../assets/images/integralDataSS.png'),
                title: "Integral Data",
                github: "https://github.com/Xristidis/IntegralData",
                githubButton: "work__item-github-link",
                animate: "work__item-img-wrapper",
                link: "https://integraldata-mock.netlify.app",
                description: "A static website built for an entreprenuer looking to advertise their services on the web. This one page site is connected to Mailchimp and uses bootstrap for its responsiveness.",
                tools: ["HTML", "Javascript", "CSS", "Bootstrap", "Mailchimp",]
            },
            {
                image: require('../assets/images/brainflixSS.png'),
                title: "Brainflix",
                github: "https://github.com/Xristidis/brainflix",
                githubButton: "work__item-github-link",
                animate: "work__item-img-wrapper",
                link: "https://brainflix-production.herokuapp.com",
                description: "Brainflix was my first ReactJS project. It was built in just over 4 days where I was given designs to implement with respect to the ReactJS ecosystem. I had to consider how I was going to break up each component on the page in order to get the most out of the library.",
                tools: ["REACTJS", "SCSS", "Axios", "NodeJS", "Express", "Heroku"]
            },
            {
                image: require('../assets/images/mentorship.png'),
                title: "Ask Mentorship",
                github: "https://github.com/Xristidis/hackalpacas",
                githubButton: "work__item-github-link",
                animate: "work__item-img-wrapper",
                link: "https://askmentorship.netlify.app",
                description: "During the course of an evening, I took part in a hackathon with a team of designers. Together we were tasked with improving how design professionals could better connect virtully while working from home. \"Ask Mentorship\" was designed as a concept and I was tasked with building the landing page, as well as deploying it to Netlify in the span of about 8 hours.",
                tools: ["HTML", "SCSS", "Flexbox", "BEM Methodology",]
            },
            {
                image: require('../assets/images/ligaRS.png'),
                title: "LIGA",
                github: "https://github.com/Xristidis/LIGA",
                githubButton: "work__item-github-link",
                animate: "work__item-img-wrapper-null",
                description: "LIGA was a capstone project built over the course of 12 days during my time at Brainstation's full stack development diploma program. After recieving mock ups from a designer, I was able to compartmentalize the app into multiple pages and components that suited the ReactJS Ecosystem. I found aggregating the data from the API into the app chllenging but learned a lot throughout the process. LIGA's development is ongoing where a database connection and a login feature are to follow in the near future.",
                tools: ["REACTJS", "Axios", "NodeJS", "Express", "SCSS"]
            },
            {
                image: require('../assets/images/sureshSS.png'),
                title: "Suresh GTA Realtor",
                // github: "http://sureshgtarealtor.ca/",
                githubButton: "work__item-github-link-null",
                animate: "work__item-img-wrapper-null",
                description: "Given the time available and features the client needed, a Wordpress website was the quickest and most cost effective way to get this particular domain online. This wordpress theme was edited with custom CSS for navbar animations and JQuery for scroll features.",
                tools: ["Wordpress", "CSS", "Javascript"]
            }
        ]
    };
    render() {
        return (
            <main>
                <NavBar />
                <Hero />
                <About header={this.state.headers.about} />
                <Work work={this.state.work} header={this.state.headers.work} />
                <Contact header={this.state.headers.contact} />
                <Footer />
            </main>
        )
    };
}
export default Homepage;