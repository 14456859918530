import React from "react";
import "../work/Work.scss"
import Header from "../header/Header";
import github from "../../assets/images/github.png";
export default class Work extends React.Component {
    render() {
        // console.log(this.props.work)
        return (
            <section className="work" id="work">
                <Header header={this.props.header} />
                <div className="work__list-wrapper">
                    {this.props.work.map(workItem => {
                        console.log(workItem.tools)
                        return (
                            <div className="work__item-wrapper">
                                <a href={workItem.link} target="_blank" className={workItem.animate}>
                                    <img src={workItem.image} alt="project screenshot" className="work__item-img" />
                                </a>
                                <div className="work__item-info">
                                    <div className="work__item-title-wrapper">
                                        <h2 className="work__item-title">{workItem.title}</h2>
                                        <a href={workItem.github} target="_blank">
                                            <input type="image" src={github} className={workItem.githubButton}>
                                            </input>
                                        </a>
                                    </div>
                                    <p className="work__item-desc">{workItem.description}</p>
                                    <div className="work__item-tools-wrapper">
                                        {workItem.tools.map(tool => {
                                            return (
                                                <span className="work__item-tool">{tool}</span>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
        )
    }
}